export const zeroPad = (val: number, digit: number) => {
  if (String(val).length > digit) return String(val);
  return ("0".repeat(digit) + val).slice(-digit);
};
export const formatSecond = (ms: number) => {
  const [hours, minutes, seconds] = devideMs(ms);
  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
};
export const devideMs = (ms: number): number[] => {
  const timer = Math.ceil(ms / 1000);
  const hours = Math.floor(timer / 3600);
  const minutes = Math.floor((timer - hours * 3600) / 60);
  const seconds = timer % 60;
  return [hours, minutes, seconds];
};
