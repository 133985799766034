import { useEffect, useState } from "react";
import { zeroPad, devideMs } from "../utils";

const Setting = ({
  timer,
  setTimer,
  setInitialTimer,
}: {
  timer: number;
  setTimer: (arg: number) => void;
  setInitialTimer: (arg: number) => void;
}) => {
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  useEffect(() => {
    const [h, m, s] = devideMs(timer);
    setHour(h);
    setMinute(m);
    setSecond(s);
  }, [timer]);
  const hours = [];
  const seconds = [];
  for (let i = 0; i < 99; i++) {
    hours.push(
      <option key={i} value={i}>
        {zeroPad(i, 2)}
      </option>
    );
  }

  for (let i = 0; i < 59; i++) {
    seconds.push(
      <option key={i} value={i}>
        {zeroPad(i, 2)}
      </option>
    );
  }
  const changeTimer = (val: string, target: number) => {
    let localTime = 0;
    switch (target) {
      case 0:
        setHour(Number(val));
        localTime = Number(val) * 3600 + minute * 60 + second;
        break;
      case 1:
        setMinute(Number(val));
        localTime = hour + Number(val) * 60 + second;
        break;
      case 2:
        setSecond(Number(val));
        localTime = hour + minute * 60 + Number(val);
        break;
    }
    setTimer(localTime * 1000);
    setInitialTimer(localTime);
  };
  return (
    <>
      <select
        className="setting"
        value={hour}
        onChange={(e) => changeTimer(e.target.value, 0)}
      >
        {hours}
      </select>
      :
      <select
        className="setting"
        value={minute}
        onChange={(e) => changeTimer(e.target.value, 1)}
      >
        {seconds}
      </select>
      :
      <select
        className="setting"
        value={second}
        onChange={(e) => changeTimer(e.target.value, 2)}
      >
        {seconds}
      </select>
    </>
  );
};
export default Setting;
