import React, { useEffect, useState, useMemo } from "react";
import useSound from "use-sound";
import Gauge from "./components/Gauge";
import Setting from "./components/Setting";
import { formatSecond } from "./utils";

import stopSound from "./stopSound.mp3";
import "./App.css";

function App() {
  const [initialTimer, setInitialTimer] = useState<number>(5);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(initialTimer * 1000);
  const [intervalId, setIntervalId] = useState<any>();
  const [showTimer, setShowTimer] = useState<boolean>(true);
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(true);
  const [playAlerm, { stop: stopAlerm }] = useSound(stopSound);
  useEffect(() => {
    if (timer === 0) {
      stopTimer();
      playAlerm();
      setShowButton(false);
      setTimeout(() => {
        stopAlerm();
      }, 5000);
    }
  }, [timer]);
  const startTimer = () => {
    setIsRunning(true);
    stopAlerm();
    const localIntervalId = setInterval(() => {
      setTimer((t) => t - 10);
    }, 10);
    setIntervalId(localIntervalId);
  };
  const resetTimer = () => {
    stopAlerm();
    setTimer(initialTimer * 1000);
    stopTimer();
    setShowButton(true);
  };
  const stopTimer = () => {
    setIsRunning(false);
    clearInterval(intervalId);
  };
  const clickTimer = () => {
    if (isRunning) return;
    setShowSetting(true);
    setShowTimer(false);
  };
  const progress = useMemo(() => {
    return Math.floor((timer / 1000 / initialTimer) * 100);
  }, [timer, initialTimer]);
  const radius = 150;
  return (
    <div className="App">
      <div id="screen">
        <div id="screen-center">
          <div id="display">
            <Gauge
              color="#000"
              r={radius}
              strokeWidth={radius / 10}
              value={progress}
            />

            <div id="timer" onClick={(e) => clickTimer()}>
              {showTimer && formatSecond(timer)}
            </div>
            {showSetting && (
              <>
                <Setting
                  timer={timer}
                  setTimer={setTimer}
                  setInitialTimer={setInitialTimer}
                />
                <button
                  id="set"
                  onClick={(e) => {
                    setShowSetting(false);
                    setShowTimer(true);
                  }}
                >
                  Set
                </button>
              </>
            )}
          </div>
          {showTimer && (
            <div className="button-area">
              {showButton && (
                <button
                  id="execute"
                  onClick={isRunning ? (e) => stopTimer() : (e) => startTimer()}
                >
                  {isRunning ? "Stop" : "Start"}
                </button>
              )}
              <button id="reset" onClick={(e) => resetTimer()}>
                Reset
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
